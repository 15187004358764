$primary-color: #e2725b;
$secondary-color: #2f2b43;
$tertiary-color: #e7e7e7;
$quaternary-color: #999ca6;
$hover-primary: #6363ff;
//$quaternary-color: #b6c0ce;
$quinary-color: #333333;
$hover-primary-color: #1348db;
$background-color: #f8f9fa;
$sucess: #4e8565;
$error: #dd5c64;
$waiting: #e5b687;
$input-background: #110A14;
$input-border: rgba(28, 25, 38, 0.5);

.none {
  pointer-events: none; /* Désactive les interactions avec l'élément */
  opacity: 0.5; /* Rend l'élément partiellement transparent s'il est non actif */
  /* Ajoutez d'autres styles de votre choix pour indiquer que l'élément est non actif */
}
