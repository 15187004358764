.accueil {
  display: flex;
  justify-content: center;
  background-color: #010210;
  height: 100vh;
  width: 100%;
  .content {
    flex-grow: 1;
    overflow: scroll;
  }

  .section-switch-nav {
    display: flex;
    width: 100%;

    justify-content: center;
  }

  .div-switch {
    margin-top: 5.56rem;
    width: 100%;
  }
}
