.settings {
  margin: 0 auto;
  width: 80%;

  h1 {
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 100% */
    letter-spacing: -0.15rem;
  }
  form {
    width: 60%;
  }
}
