:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #0B0B15;
  --primary-color: #e2725b;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #ffffff;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

.aside-navsidebar {
  background: var(--sidebar-color);
  border: 1px solid rgba(255, 255, 255, 0.03);
  z-index: 100;
  transition: var(--tran-05);
  width: 16rem;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  left: 0;
  padding-bottom: 20px;
  /* padding-left: 30px; */
  padding-top: 20px;
  /* padding-right: 30px; */
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    display: flex;
    justify-content: end;
    padding-right: 15px;
    margin-top: 0.4rem;
    cursor: pointer;
    width: 230px;
  }

  .nav-logo {
    display: flex;
    align-items: center;
    margin-top: 35px;

    h1 {
      font-size: 1.3rem;
      margin-left: 6px;
      font-weight: 600;
      color: white;
    }

    span {
      font-weight: 200;
    }
  }

  .nav-sidebar {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    img {
      width: 22px;
      margin-left: 7px;
    }
    .img {
      width: 22px;
      margin-left: 7px;
    }

    .nav-links {
      margin-left: 0.75rem;
      font-size: 0.875rem;
      font-weight: 500;

      letter-spacing: -0.03rem;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #989898;
      padding: 0.5rem;
      width: 230px;
    }
  }

  footer {
    display: block;
    flex: 0 0 auto;
    margin-top: auto;

    .nav-links {
      margin-left: 0.7rem;
      font-size: 0.875rem;
      font-weight: 500;

      letter-spacing: -0.03rem;
    }

    img {
      width: 22px;
      margin-left: 7px;
    }

    .logout {
      padding: 0.5rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #989898;
      width: 230px;
      cursor: pointer;

      &:hover {
        filter: brightness(0) saturate(58%) invert(66%) sepia(45%)
          saturate(2678%) hue-rotate(-38deg) brightness(89%) contrast(93%);
        background: rgba(226, 114, 91, 0.05);
      }
    }
  }

  .hover-link.active {
    filter: brightness(0) saturate(58%) invert(66%) sepia(45%) saturate(2678%)
      hue-rotate(-38deg) brightness(89%) contrast(93%);
    background: rgba(226, 114, 91, 0.05);

    padding: 0.5rem;
    /* margin-right: 25px; */
    border-radius: 0.3rem;
    width: 230px;
  }

  .hover-link {
    padding: 0.5rem;
  }

  /* Pour le style au survol */
  .hover-link:hover {
    filter: brightness(0) saturate(58%) invert(66%) sepia(45%) saturate(2678%)
      hue-rotate(-38deg) brightness(89%) contrast(93%);
    background: rgba(226, 114, 91, 0.05);
    //     padding: 0.5rem;
    // margin-right: 25px;
    // border-radius: 0.5rem;
  }
}

.aside-close {
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  padding: 20px;
  transition: all 0.5s;
  width: 6rem;
  z-index: 2234;
  align-items: center;
  background-color: #0B0B15;
  height: 100vh;
  border-right: 1px solid rgba(255, 255, 255, 0.03);
  justify-content: center;
  align-items: center;

  .arrow {
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .nav-sidebar {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    img {
      width: 22px;
    }
    .img {
      width: 22px;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #989898;
      margin-bottom: 3px;
      &:active {
        filter: brightness(0) saturate(58%) invert(66%) sepia(45%)
          saturate(2678%) hue-rotate(-38deg) brightness(89%) contrast(93%);
      }
    }
  }
  .text {
    opacity: 0;
  }

  header {
    .toggle {
      transform: translateY(-50%);
    }
  }
  .menu-title {
    opacity: 0;
  }

  .text-disable {
    display: none;
  }

  .display-none {
    display: none;
  }

  footer {
    display: block;
    flex: 0 0 auto;
    margin-top: auto;

    .nav-links {
      margin-left: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
    }

    .logout {
      padding: 15px 15px;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #989898;

      cursor: pointer;

      &:hover {
        filter: brightness(0) saturate(58%) invert(66%) sepia(45%)
          saturate(2678%) hue-rotate(-38deg) brightness(89%) contrast(93%);
        background: rgba(226, 114, 91, 0.05);
      }
    }
  }

  .hover-link.active {
    filter: brightness(0) saturate(58%) invert(66%) sepia(45%) saturate(2678%)
      hue-rotate(-38deg) brightness(89%) contrast(93%);
    background: rgba(226, 114, 91, 0.05);
    padding: 15px 15px;

    border-radius: 0.5rem;
  }

  .hover-link {
    padding: 15px 15px;
  }

  /* Pour le style au survol */
  .hover-link:hover {
    filter: brightness(0) saturate(58%) invert(66%) sepia(45%) saturate(2678%)
      hue-rotate(-38deg) brightness(89%) contrast(93%);
    background: rgba(226, 114, 91, 0.05);
    padding: 15px 15px;

    border-radius: 0.5rem;
  }
}
